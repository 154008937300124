import { computed, effect, inject, Injectable, signal } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import { NewItemDialogComponent } from '@app/shared/components/new-item/dialog/new-item-dialog.component';
import {
  CloudTaskStatus,
  FileDetails,
  Phase,
  PhaseRunSetup,
  PhasesService,
  Project,
  ProjectOptions,
  ProjectsService,
  ScenariosService,
  StudiesService,
  Study,
  SubmitTask,
  Workload,
  WorkloadsService,
} from '@cloud-api';
import {
  catchError,
  filter,
  Observable,
  of,
  switchMap,
  take,
  tap,
  throwError,
} from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ProjectStore {
  // Injected services
  private readonly dialog = inject(MatDialog);
  private readonly phasesService = inject(PhasesService);
  private readonly projectsService = inject(ProjectsService);
  private readonly router = inject(Router);
  private readonly studiesService = inject(StudiesService);
  private readonly scenariosService = inject(ScenariosService);
  private readonly workloadsService = inject(WorkloadsService);

  // State signals
  private project = signal<Project | undefined>(undefined);
  private workload = signal<Workload | undefined>(undefined);
  private study = signal<Study | undefined>(undefined);
  private files = signal<FileDetails[] | undefined>(undefined);
  private studies = signal<Study[] | undefined>(undefined);
  private workloads = signal<Workload[] | undefined>(undefined);
  private projectId = signal<string | undefined>(undefined);
  private workloadId = signal<string | undefined>(undefined);
  private studyId = signal<string | undefined>(undefined);
  private submittedScenarios = signal<string[]>([]);

  // Computed signals for derived state (similar to selectors)
  readonly projectIdComputed = computed(() => this.projectId());
  readonly workloadIdComputed = computed(() => this.workloadId());
  readonly projectComputed = computed(() => this.project());
  readonly studyComputed = computed(() => this.study());
  readonly workloadComputed = computed(() => this.workload());
  readonly projectOptions = computed(() => this.project()?.projectOptions);
  readonly workloadFiles = computed(() => this.files());
  readonly workloadsComputed = computed(() => this.workloads());
  readonly studiesComputed = computed(() => this.studies());
  readonly submittingComputed = computed(() => this.submittedScenarios());

  // Effects to handle side-effects (similar to NgRx effects)
  readonly loadProjectEffect = effect((onCleanup) => {
    const projectId = this.projectId();
    if (projectId) {
      const subscription = this.projectsService
        .getProject(projectId)
        .subscribe({
          next: (project) => {
            this.loadProject(project);
            this.loadWorkloads(project.workloads);
            this.loadStudies(project.studies);
          },
          error: (error) => console.error('Failed to load project', error),
          complete: () => subscription.unsubscribe(),
        });
      onCleanup(() => subscription.unsubscribe());
    }
  });

  readonly loadWorkloadEffect = effect((onCleanup) => {
    const projectId = this.projectId();
    const workloadId = this.workloadId();
    if (projectId && workloadId) {
      const subscription = this.workloadsService
        .getWorkload(projectId, workloadId)
        .subscribe({
          next: (workload) => this.loadWorkload(workload),
          error: (error) => console.error('Failed to load workload', error),
          complete: () => subscription.unsubscribe(),
        });
      onCleanup(() => subscription.unsubscribe());
    }
  });

  readonly loadStudyEffect = effect((onCleanup) => {
    const projectId = this.projectId();
    const studyId = this.studyId();
    if (projectId && studyId) {
      const subscription = this.studiesService
        .getStudy(projectId, studyId)
        .subscribe({
          next: (study) => this.loadStudy(study),
          error: (error) => console.error('Failed to load study', error),
          complete: () => subscription.unsubscribe(),
        });
      onCleanup(() => subscription.unsubscribe());
    }
  });

  readonly loadWorkloadFilesEffect = effect((onCleanup) => {
    const projectId = this.projectId();
    const workloadId = this.workloadId();
    if (projectId && workloadId) {
      const subscription = this.workloadsService
        .getWorkloadFileList(projectId, workloadId)
        .subscribe({
          next: (files) => this.loadWorkloadFiles(files),
          error: (error) =>
            console.error('Failed to load workload files', error),
          complete: () => subscription.unsubscribe(),
        });
      onCleanup(() => subscription.unsubscribe());
    }
  });

  addScenario(cloneId?: string) {
    const projectId = this.projectId();
    const studyId = this.studyId();

    if (studyId == undefined || projectId == undefined)
      return throwError(() => new Error('Project or Study is not defined'));

    const dlg = this.dialog.open(NewItemDialogComponent, {
      panelClass: 'mat-dialog-override',
      width: '100vw',
      height: '100%',
      maxHeight: 900,
      maxWidth: 576,
      data: {
        title: cloneId ? 'scenario.clone' : 'study.add-new',
        name: '',
        description: '',
      },
    });

    return dlg.afterClosed().pipe(
      take(1),
      switchMap((res) => {
        if (res != '' && res != undefined) {
          if (!cloneId) {
            return this.scenariosService.createScenario(
              projectId,
              studyId,
              res,
            );
          } else {
            return this.scenariosService.cloneScenario(
              projectId,
              studyId,
              cloneId,
              res,
            );
          }
        } else {
          return of(null);
        }
      }),
      take(1),
      tap((scenario) => {
        if (scenario) {
          this.study.update((currentStudy) => {
            if (!currentStudy) return undefined; // Handle if project is undefined

            // Return a new object with an updated scenarios array
            return {
              ...currentStudy,
              scenarios: [...currentStudy.scenarios, scenario],
            };
          });
        }
      }),
      catchError((error) => {
        console.error('Failed to addd scenario', error);
        return throwError(() => new Error('Failed to add scenario'));
      }),
    );
  }

  addPhase(scenarioId: string, phase: Phase) {
    const projectId = this.projectId();
    const studyId = this.studyId();

    if (studyId == undefined || projectId == undefined)
      return throwError(() => new Error('Project or Study is not defined'));

    return this.phasesService
      .createPhase(projectId, studyId, scenarioId, phase)
      .pipe(
        take(1),
        tap((newPhase) => {
          if (newPhase) {
            this.study.update((currentStudy) => {
              if (!currentStudy) return undefined; // Handle if project is undefined

              // Find the scenario to update by scenarioId
              const updatedScenarios = currentStudy.scenarios.map(
                (scenario) => {
                  if (scenario.id === scenarioId) {
                    // Return a new scenario object with the new phase added to its phases array
                    return {
                      ...scenario,
                      phases: [...(scenario.phases || []), newPhase], // Add new phase to the phases array
                    };
                  }
                  return scenario; // Return the other scenarios unchanged
                },
              );

              // Return a new study object with the updated scenarios
              return {
                ...currentStudy,
                scenarios: updatedScenarios,
              };
            });
          }
        }),
        catchError((error) => {
          console.error('Failed to addd phase', error);
          return throwError(() => new Error('Failed to add phase'));
        }),
      );
  }

  saveProjectOptions(projectOptions: ProjectOptions) {
    const projectId = this.projectId();

    if (projectId == undefined)
      return throwError(() => new Error('Project is not defined'));

    return this.projectsService
      .putProjectOptions(projectId, projectOptions)
      .pipe(
        take(1),
        tap((res) => {
          console.log(res);
          this.project.update((currentProject) => {
            if (!currentProject) return undefined; // Handle if project is undefined

            // Return a new object with an updated scenarios array
            return {
              ...currentProject,
              projectOptions: projectOptions,
            };
          });
        }),
      );
  }

  deleteStudy(studyId: string): Observable<void> {
    const projectId = this.projectId();

    if (projectId && studyId) {
      return this.studiesService.deleteStudy(projectId, studyId).pipe(
        tap(() => {
          // Update the store after successful deletion
          const updatedStudies = (this.studies() ?? []).filter(
            (s) => s.id !== studyId,
          );
          this.loadStudies(updatedStudies);
          if (this.studyId() == studyId) {
            this.studyId.set(undefined);
            this.study.set(undefined);
          }
        }),
        catchError((error) => {
          console.error('Failed to delete study', error);
          return throwError(() => new Error('Failed to delete study'));
        }),
      );
    }

    return throwError(() => new Error('Project or Study is not defined'));
  }

  deleteScenario(scenarioId: string): Observable<void> {
    const projectId = this.projectId();
    const studyId = this.studyId();

    if (projectId && studyId && scenarioId) {
      return this.scenariosService
        .deleteScenario(projectId, studyId, scenarioId)
        .pipe(
          tap(() => {
            // Update the store after successful deletion
            this.study.update((currentStudy) => {
              if (!currentStudy) return undefined; // If no project exists, return undefined

              // Return a new project object with the updated scenarios array (filtered to remove the scenario)
              return {
                ...currentStudy, // Spread the existing properties of the project
                scenarios: currentStudy.scenarios.filter(
                  (scenario) => scenario.id !== scenarioId,
                ), // Remove scenario by ID
              };
            });
          }),
          catchError((error) => {
            console.error('Failed to delete study', error);
            return throwError(() => new Error('Failed to delete study'));
          }),
        );
    }

    return throwError(() => new Error('Project or Study is not defined'));
  }

  deletePhase(scenarioId: string, phaseId: number) {
    const projectId = this.projectId();
    const studyId = this.studyId();

    if (studyId == undefined || projectId == undefined)
      return throwError(() => new Error('Project or Study is not defined'));

    return this.phasesService
      .deletePhase(projectId, studyId, scenarioId, phaseId)
      .pipe(
        take(1),
        tap((res) => {
          this.study.update((currentStudy) => {
            if (!currentStudy) return undefined; // Handle if study is undefined

            // Find the scenario and remove the phase by phaseId
            const updatedScenarios = currentStudy.scenarios.map((scenario) => {
              if (scenario.id === scenarioId) {
                // Filter out the phase by ID and reindex the remaining phases
                const reindexedPhases =
                  scenario.phases
                    ?.filter((phase) => phase.id !== phaseId) // Remove the deleted phase
                    .map((phase, index) => ({ ...phase, id: index + 1 })) || []; // Reindex starting from 1

                // Return the updated scenario with reindexed phases
                return {
                  ...scenario,
                  phases: reindexedPhases,
                };
              }
              return scenario; // Return other scenarios unchanged
            });

            // Return a new study object with the updated scenarios
            return {
              ...currentStudy,
              scenarios: updatedScenarios,
            };
          });
        }),
        catchError((error) => {
          console.error('Failed to delete phase', error);
          return throwError(() => new Error('Failed to delete phase'));
        }),
      );
  }

  // Method to edit a phase
  editWorkloadRunSetup(runSetup: PhaseRunSetup) {
    const projectId = this.projectId();
    const workloadId = this.workloadId();

    if (workloadId == undefined || projectId == undefined)
      return throwError(() => new Error('Project or Workload is not defined'));

    return this.workloadsService
      .putWorkloadRunSetup(projectId, workloadId, runSetup)
      .pipe(
        take(1),
        tap(() => {
          this.workload.update((currentWorkload) => {
            if (!currentWorkload) return undefined; // Handle if workload is undefined

            // Return a new workload object with the updated setup
            return {
              ...currentWorkload,
              defaultRunSetup: runSetup,
            };
          });
        }),
        catchError((error) => {
          console.error('Failed to edit workload', error);
          return throwError(() => new Error('Failed to edit workload'));
        }),
      );
  }

  // Method to edit a phase
  editPhase(scenarioId: string, phase: Phase) {
    const projectId = this.projectId();
    const studyId = this.studyId();

    if (studyId == undefined || projectId == undefined)
      return throwError(() => new Error('Project or Study is not defined'));

    return this.phasesService
      .putPhase(projectId, studyId, scenarioId, phase)
      .pipe(
        take(1),
        tap(() => {
          this.study.update((currentStudy) => {
            if (!currentStudy) return undefined; // Handle if study is undefined

            // Find the scenario and update the phase
            const updatedScenarios = currentStudy.scenarios.map((scenario) => {
              if (scenario.id === scenarioId) {
                const updatedPhases =
                  scenario.phases?.map((existingPhase) => {
                    if (existingPhase.id === phase.id) {
                      return phase; // Update the phase details
                    }
                    return existingPhase; // Return other phases unchanged
                  }) || [];

                // Return the updated scenario with updated phases
                return {
                  ...scenario,
                  phases: updatedPhases,
                };
              }
              return scenario; // Return other scenarios unchanged
            });

            // Return a new study object with the updated scenarios
            return {
              ...currentStudy,
              scenarios: updatedScenarios,
            };
          });
        }),
        catchError((error) => {
          console.error('Failed to edit phase', error);
          return throwError(() => new Error('Failed to edit phase'));
        }),
      );
  }

  submitScenario(scenarioId: string, submitTask: SubmitTask) {
    const projectId = this.projectId();
    const studyId = this.studyId();

    if (studyId == undefined || projectId == undefined)
      return throwError(() => new Error('Project or Study is not defined'));

    // Check if scenario is alrewady being submitted
    if (this.submittedScenarios().indexOf(scenarioId) > -1)
      return throwError(() => new Error('Scenario already submitted.'));

    this.submittedScenarios.update((s) => [...s, scenarioId]);

    return this.scenariosService
      .submitScenarioTask(projectId, studyId, scenarioId, submitTask)
      .pipe(
        tap({
          complete: () => {
            this.submittedScenarios.update((s) =>
              s.filter((st) => st !== scenarioId),
            );

            this.study.update((currentStudy) => {
              if (!currentStudy) return undefined; // Handle if study is undefined

              // Find the scenario and update the status
              const updatedScenarios = currentStudy.scenarios.map(
                (scenario) => {
                  if (scenario.id === scenarioId) {
                    // Return the updated scenario with new status
                    return {
                      ...scenario,
                      taskStatus: CloudTaskStatus.Submitted,
                      phases: scenario.phases?.map((phase) => ({
                        ...phase,
                        taskStatus: CloudTaskStatus.Submitted,
                        completeRuns: 0, // Set completeRuns to 0
                        failedRuns: 0, // Set failedRuns to 0

                      })) ?? null,
                    };
                  }
                  return scenario; // Return other scenarios unchanged
                },
              );

              // Return a new study object with the updated scenarios
              return {
                ...currentStudy,
                scenarios: updatedScenarios,
              };
            });
          },
        }),
      );
  }

  submitPhase(scenarioId: string, phaseId: number, submitTask: SubmitTask) {
    const projectId = this.projectId();
    const studyId = this.studyId();

    if (studyId == undefined || projectId == undefined)
      return throwError(() => new Error('Project or Study is not defined'));

    // Check if scenario is alrewady being submitted
    if (this.submittedScenarios().indexOf(scenarioId) > -1)
      return throwError(() => new Error('Project or Study is not defined'));

    const phase_id = scenarioId + '_' + phaseId;

    this.submittedScenarios.update((s) => [...s, phase_id]);

    return this.phasesService
      .submitPhaseTask(projectId, studyId, scenarioId, phaseId, submitTask)
      .pipe(
        tap(() =>
          this.submittedScenarios.update((s) =>
            s.filter((st) => st !== phase_id),
          ),
        ),
      );
  }

  phaseComplete(scenarioId: string, phaseId: number, completed: number) {
    const projectId = this.projectId();
    const studyId = this.studyId();

    if (studyId == undefined || projectId == undefined) return false;

    this.study.update((currentStudy) => {
      if (!currentStudy) return undefined; // Handle if study is undefined

      // Find the scenario and update the phase's complete status by phaseId
      const updatedScenarios = currentStudy.scenarios.map((scenario) => {
        if (scenario.id === scenarioId) {
          const updatedPhases =
            scenario.phases?.map((phase) => {
              if (phase.id === phaseId) {
                return { ...phase, taskStatus: CloudTaskStatus.Complete, completeRuns: completed }; // Update complete status
              }
              return phase; // Return other phases unchanged
            }) || [];

          // Update scenario taskStatus based on phase completion
          if (
            updatedPhases.findIndex(
              (phase) =>
                phase.taskStatus != CloudTaskStatus.Complete &&
                phase.taskStatus != CloudTaskStatus.Error,
            ) === -1
          ) {
            scenario.taskStatus = CloudTaskStatus.Complete;
          }

          // Return the updated scenario with updated phases
          return {
            ...scenario,
            phases: updatedPhases,
          };
        }
        return scenario; // Return other scenarios unchanged
      });

      // Return a new study object with the updated scenarios
      return {
        ...currentStudy,
        scenarios: updatedScenarios,
      };
    });

    return true;
  }

  // Method to refresh studies manually
  refreshStudies() {
    const projectId = this.projectId();
    if (projectId) {
      const subscription = this.projectsService
        .getProjectStudies(projectId)
        .subscribe({
          next: (studies) => this.loadStudies(studies),
          error: (error) => console.error('Failed to load studies', error),
          complete: () => subscription.unsubscribe(),
        });
    }
  }

  // Method to refresh workloads manually
  refreshWorkloads() {
    const projectId = this.projectId();
    if (projectId) {
      const subscription = this.projectsService
        .getProjectWorkloads(projectId)
        .subscribe({
          next: (workloads) => this.loadWorkloads(workloads),
          error: (error) => console.error('Failed to load workloads', error),
          complete: () => subscription.unsubscribe(),
        });
    }
  }

  // Method to refresh workload files manually
  refreshWorkloadFiles() {
    const projectId = this.projectId();
    const workloadId = this.workloadId();
    if (projectId && workloadId) {
      const subscription = this.workloadsService
        .getWorkloadFileList(projectId, workloadId)
        .subscribe({
          next: (files) => this.loadWorkloadFiles(files),
          error: (error) =>
            console.error('Failed to load workload files', error),
          complete: () => subscription.unsubscribe(),
        });
    }
  }

  // Methods to update state (similar to dispatching actions)
  loadProject(project: Project) {
    this.project.set(project);
  }

  loadWorkload(workload: Workload) {
    this.workload.set(workload);
  }

  loadStudy(study: Study) {
    this.study.set(study);
  }

  loadWorkloadFiles(files: FileDetails[]) {
    this.files.set(files);
  }

  loadStudies(studies: Study[]) {
    this.studies.set(studies);
  }

  loadWorkloads(workloads: Workload[]) {
    this.workloads.set(workloads);
  }

  resetProject() {
    this.project.set(undefined);
  }

  clearProjectData() {
    this.projectId.set(undefined);
    this.workloadId.set(undefined);
    this.studyId.set(undefined);
    this.project.set(undefined);
    this.workload.set(undefined);
    this.study.set(undefined);
    this.files.set(undefined);
    this.workloads.set(undefined);
  }

  // Method to get user role by ID
  getUserRole(id: string | undefined): string | undefined {
    return this.project()?.projectAppUsers?.find((u) => u.id === id)?.role;
  }

  // Effect to listen to route changes and set the projectId
  readonly setProjectIdFromRouteEffect = effect((onCleanup) => {
    const subscription = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        var params = {};
        var route = this.router.routerState.snapshot.root;
        do {
          params = { ...params, ...route.params };
          route = route.firstChild;
        } while (route);
        const projectId = params['projectid'];
        const workloadId = params['workloadid'];
        const studyid = params['studyid'];
        this.projectId.set(projectId);
        this.workloadId.set(workloadId);
        this.studyId.set(studyid);
      });
    onCleanup(() => subscription.unsubscribe());
  });
}
